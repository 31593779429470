@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Your custom styles here */


.product-description ul {
    list-style-type: disc;
    padding-left: 1.5rem;
}
.product-description ul li {
    margin-bottom: 0.5rem;
}